<script>
import { v4 as uuid } from "uuid";
import { CLIENTE as clientMenu } from "@/core/constants/menus";
import { difference } from 'lodash';

const allwaysSelected = new Set();
const permissionsById = new Map();
const idsByPermission = new Map();

export default {
  props: {
    items: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      permissions: [],
      selection: [],
    };
  },
  computed: {
    computedSelection() {
      return [...this.selection, ...allwaysSelected.values()];
    },
  },
  created() {
    this.permissions = this.mapMenuChildren(clientMenu);
    this.selection = this.mapPermissions(this.value);
  },
  model: {
    event: "change",
    prop: "value"
  },
  watch: {
    value(nextValue, prevValue) {
      if (difference(nextValue, prevValue).length > 0) {
        this.selection = this.mapPermissions(nextValue);
      }
    },
  },
  methods: {
    mapMenuChildren(menus) {
      if (!Array.isArray(menus)) {
        return [];
      }

      return menus.map(({ children, permissions, ...rest }) => {
        const hasChildren = Array.isArray(children) && children.length > 0;
        const hasPermissions = Array.isArray(permissions) && permissions.length > 0;
        const item = {
          ...rest,
          children: undefined,
          disabled: false,
          id: uuid(),
        };

        if (hasChildren) {
          item.children = this.mapMenuChildren(children);
        } else if (hasPermissions) {
          permissionsById.set(item.id, permissions);
          permissions.forEach((permission) => {
            const prev = idsByPermission.get(permission) ?? [];

            if (!prev.includes(item.id)) {
              prev.push(item.id);
              idsByPermission.set(permission, prev);
            }
          });
        } else {
          item.disabled = true;
          this.selection.push(item.id);
          allwaysSelected.add(item.id);
        }

        return item;
      });
    },
    mapPermissions(permissions) {
      if (!Array.isArray(permissions) || !permissions.length) {
        return [];
      }

      const values = permissions.reduce((acc, permission) => {
        const ids = idsByPermission.get(permission) ?? [];
        const uniq = ids.filter((id) => !acc.includes(id));
        return [...acc, ...uniq];
      }, []);
      return [...values, ...allwaysSelected.values()];
    },
    setSelection(value) {
      if (!Array.isArray(value)) {
        return;
      }

      const allIds = value.filter((id) => !allwaysSelected.has(id));
      const excludedIds = difference(this.selection, allIds);
      const [permissions, excludedPermissions] = [allIds, excludedIds].map((targetIds) => targetIds.reduce((acc, id) => {
        const permissions = permissionsById.get(id) ?? [];
        const uniq = permissions.filter((permission) => !acc.includes(permission));
        return [...acc, ...uniq];
      }, []));
      const idsShouldExclude = excludedPermissions.reduce((acc, permission) => {
        const ids = idsByPermission.get(permission) ?? [];
        const uniq = ids.filter((id) => !acc.includes(id));
        return [...acc, ...uniq];
      }, []);
      const ids = difference(allIds, idsShouldExclude);
      this.selection = ids;
      this.$emit("change", permissions);
    }
  },
};
</script>

<template>
  <v-treeview
    :items="permissions"
    selectable
    :value="computedSelection"
    @input="setSelection"
  ></v-treeview>
</template>

<style scoped lang="scss">
::v-deep.v-treeview .v-treeview-node__root {
  min-height: 1.75rem;

  .v-treeview-node__checkbox.v-treeview-node__checkbox {
    color: #81B4EA !important;
    caret-color: #81B4EA !important;
  }
}
</style>
